import Vue from 'vue'
import VueRouter from 'vue-router'
import EmailEditor from '@/modules/email-editor/EmailEditor.vue'
import store from '../store'
import Dashboard from '@/views/dashboard-v2'
import Orders from '@/views/orders/Orders'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'dashboard',
        meta: {
            layout: "main",
            title: "Dashboard | Funnelish™"
        },
        component: Dashboard
    },
    {
        path: '/analytics',
        name: 'Analytics',
        meta: { layout: "main" },
        component: () => import ('@/views/analytics/index'),
        children: [
            {
                path: '',
                name: 'AnalyticsOverview',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/overview'),
            },
            {
                path: 'live-view',
                name: 'AnalyticsLiveView',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/live-view'),
            },
            {
                path: 'revenue',
                name: 'AnalyticsRevenue',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/revenue.vue'),
            },
            {
                path: 'device-performance',
                name: 'AnalyticsDevicePerformance',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/device-performance.vue'),
            },
            {
                path: 'funnel-performance',
                name: 'AnalyticsFunnelPerformance',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/funnel-performance.vue'),
            },
            {
                path: 'payment-methods',
                name: 'AnalyticsPaymentMethods',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/payment-methods.vue'),
            },
            {
                path: 'utm-tags',
                name: 'AnalyticsUtmTags',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/utm-tags-performance.vue'),
            },
            {
                path: 'traffic',
                name: 'AnalyticsTraffic',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/traffic.vue'),
            },
            {
                path: 'aov',
                name: 'AnalyticsAov',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/aov.vue'),
            },
            {
                path: 'sales',
                name: 'AnalyticsSales',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/sales.vue'),
            },
            {
                path: 'conversion-rates',
                name: 'AnalyticsConvRates',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/conversion-rates.vue'),
            },
            {
                path: 'optins',
                name: 'AnalyticsOptins',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/optins.vue'),
            },
            {
                path: 'profitability-report',
                name: 'AnalyticsProfitabilityReport',
                meta: { layout: "main" },
                component: () => import ('@/views/analytics/profitability-report.vue'),
            },
        ]
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: { layout: "main" },
        component: () => import ('@/views/settings/settings'),
        children: [
            {
                path: 'staff',
                name: 'staff',
                meta: { layout: "main" },
                component: () => import('@/views/settings/staff'),
                children: [
                    {
                        path: 'new',
                        name: 'new',
                        meta: { layout: "main" },
                        component: () => import('@/views/settings/staff/new'),
                    },
                    {
                        path: 'edit/:id',
                        name: 'edit',
                        meta: { layout: "main" },
                        component: () => import('@/views/settings/staff/edit'),
                    },
                ]
            },
            {
                path: 'account',
                name: 'account',
                meta: { layout: "main" },
                component: () => import('@/views/settings/account'),
            },
            {
                path: 'profile',
                name: 'profile',
                meta: { layout: "main" },
                component: () => import('@/views/settings/profile'),
            },
            {
                path: 'billing',
                name: 'Billing',
                meta: { layout: "main" },
                component: () => import ('@/views/settings/billing/Billing'),
                children: [
                    {
                        path:'choose-your-plan',
                        name: 'choose-your-plan',
                        meta: { layout: "main" },
                        component: () => import('@/views/settings/billing/choose-your-plan'),
                    },
                    {
                        path:'checkout',
                        name: 'checkout',
                        meta: { layout: "main" },
                        component: () => import('@/views/settings/billing/checkout'),
                    },
                    {
                        path:'/',
                        name: 'active-billing',
                        meta: { layout: "main" },
                        component: () => import('@/views/settings/billing/active-billing'),
                    },
                    {
                        path:'payment-successful',
                        name: 'payment-successful',
                        meta: { layout: "main" },
                        component: () => import('@/views/settings/billing/payment-successful'),
                    },
                ]
            },
        ]
    },
    {
        path: '/funnels',
        name: 'Funnels',
        meta: {
            layout: "main",
            title: "All Funnels | Funnelish™"
        },
        component: () => import ('@/views/funnels/index'),
        children: [
            {
                path: '/',
                meta: {layout: "main"},
                component: () => import ('@/views/funnels/all-funnels'),
            },
            {
                path: 'archive',
                meta: {layout: "main"},
                component: () => import ('@/views/funnels/archive'),
            },
        ]
    },
    {
        path: '/automations',
        name: 'automations',
        meta: {
            layout: "main",
            title: "Automations | Funnelish™",
            is_global:true
        },
        component: () => import('@/views/automations/index'),
        children: [
            {
                path: '/',
                meta: {layout: "main",is_global:true},
                component: () => import ('@/views/automations/all-automations'),
            },
            {
                path: 'archive',
                meta: {layout: "main",is_global:true},
                component: () => import ('@/views/automations/archive'),
            },
            {
                path: ':id',
                meta: {layout: "main",is_workflow:true,is_global:true},
                component: () => import ('@/views/automations/automation'),
            },
        ]
    },
    {
        path: '/orders',
        name: 'Orders',
        props: true,
        meta: {
            layout: "main",
            title: "All Orders | Funnelish™"
        },
        component: Orders,
    },
    {
        path: '/apps',
        name: 'Apps',
        props: true,
        meta: {
            layout: "main",
            title: "Apps | Funnelish™"
        },
        component: () =>
            import ('@/views/apps/Apps'),

        children: [{
                path: ':id/connect-redirect',
                meta: {
                    layout: "main",
                    title: "Connect redirect | Funnelish™"
                },
                component: () =>
                    import ('@/views/apps/connect-redirect'),
            }, {
                path: ':id',
                meta: {
                    layout: "main",
                    title: "App Details | Funnelish™"
                },
                component: () =>
                    import ('@/views/apps/AppDetails'),
            }
        ]
    },
    {
        path: '/analytics',
        name: 'Analytics',
        props: true,
        meta: {
            layout: "main",
            title: "Analytics | Funnelish™"
        },
        component: () =>
            import ('@/views/Analytics')
    },
    {
        path: '/customers',
        name: 'Customers',
        meta: {
            layout: "main",
            title: "Customers | Funnelish™"
        },
        component: () =>
            import ('@/views/customers/Customers'),
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        meta: { layout: "empty", noPassword:true },
        component: () =>
            import ('@/views/login/SignUp')
    },
    {
        path: '/share',
        name: 'ShareFunnel',
        meta: { layout: "empty", noPassword:true },
        component: () =>
            import ('@/views/share/share-funnel')
    },
    {
        path: '/log-in',
        name: 'LogIn',
        meta: { layout: "empty", noPassword:true },
        component: () =>
            import ('@/views/login/Login')
    },
    {
        path: '/select-account',
        name: 'SelectAccount',
        meta: { layout: "empty", noPassword:true },
        component: () =>
            import ('@/views/login/select-account')
    },
    {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        meta: { layout: "empty", noPassword:true },
        component: () =>
            import ('@/views/login/ForgotPassword')
    },
    {
        path: '/new-password',
        name: 'NewPassword',
        meta: { layout: "empty", noPassword:true },
        component: () =>
            import ('@/views/login/new_password')
    },
    {
        path: '/get-started',
        name: 'GetStarted',
        meta: { layout: "main" },
        component: () =>
            import ('@/views/get-started/get-started')
    },
    {
        path: '/get-started/templates',
        name: 'GetStartedTemplates',
        meta: { layout: "main" },
        component: () =>
            import ('@/views/get-started/templates')
    },
    {
        path: '/integrations',
        name: 'Integrations',
        props: true,
        meta: {
            layout: "main"
        },
        component: () =>
            import ('@/views/integrations/Integrations'),
        children: [{
            path: ':id',
            meta: { layout: "main" },
            component: () =>
                import ('@/views/integrations/IntegrationsDetails'),
        }]
    },
    {
        path: '/store',
        name: 'Store',
        props: true,
        meta: {
            layout: "main"
        },
        redirect: '/store/discounts',
        component: () =>
            import ('@/views/store/store'),
        children: [{
            path: 'discounts',
            meta: { layout: "main" },
            component: () =>
                import ('@/views/store/discounts'),
        }]
    },
    {
        path: '/developers',
        name: 'Developers',
        props: true,
        meta: {
            layout: "main"
        },
        redirect: '/developers/apps',
        component: () =>
            import ('@/views/developers'),
        children: [{
            path: 'apps',
            meta: { layout: "main" },
            component: () =>
                import ('@/views/developers/apps'),
        }]
    },
    {
        path: '/funnel/:id',
        name: 'funnel',
        meta: { layout: 'steps-children' },
        props: true,
        component: () =>
            import ('@/views/funnels/funnel/FunnelRoot.vue'),

        children: [{
                path: '',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel/FunnelOnboarding.vue')
            },
            {
                path: 'settings',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel/Settings')
            },
            {
                path: 'orders',
                meta: { layout: 'steps-children' },
                props: true,
                component: () =>
                    import ('@/views/funnels/funnel/orders'),
            },
            {
                path: 'analytics',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel/Analytics')
            },
            {
                path: 'apps',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/apps/Apps'),

                children: [{
                    path: ':appId',
                    meta: { layout: 'steps-children' },
                    component: () =>
                        import ('@/views/funnels/apps/AppDetails')
                }]
            },
            {
                path: 'automations',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel/Automations'),
                children: [{
                    path: ':automation',
                    meta: { layout: 'steps-children' },
                    name: 'nodeData',
                    props: true,
                    component: () =>
                        import ('@/components/workflow/components/ParentCanvas'),
                    children: [{
                        path: ':email',
                        meta: { layout: 'steps-children' },
                        name: 'EmailTemplate',
                        props: true,
                        component: () =>
                            import ('@/components/workflow/components/ParentCanvas'),
                    }]
                }]
            },
            {
                path: 'steps/:step',
                name: 'funnel-with-steps-children',
                meta: { layout: 'steps-children' },
                props: true,
                component: () =>
                    import ('@/views/funnels/steps/funnel_step'),

                children: [{
                        path: 'settings',
                        meta: { layout: 'steps-children' },
                        props: true,
                        component: () =>
                            import ('@/views/funnels/steps/settings')
                    },
                    {
                        path: 'product/:productId',
                        name: 'product-route',
                        meta: { layout: 'steps-children' },
                        props: true,
                        component: () =>
                            import ('@/views/funnels/steps/product-side-panel')
                    }
                ]
            },
        ]
    },
    {
        path: '/wfunnel/:id',
        name: 'funnel-plus-root',
        meta: { layout: 'steps-children', isFPlus:true },
        props: true,
        component: () =>
            import ('@/views/funnels/funnel/FunnelRoot.vue'),

        children: [{
                path: '',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel-plus/funnel/FunnelOnboarding')
            },
            {
                path: 'settings',
                name: 'funnel-plus-settings',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel-plus/funnel/settings.vue')
            },
            {
                path: 'orders',
                meta: { layout: 'steps-children' },
                props: true,
                component: () =>
                    import ('@/views/funnels/funnel-plus/funnel/orders/index'),

                children: [{
                    path: ':orderId',
                    meta: { layout: 'steps-children' },
                    props: true,
                    component: () =>
                        import ('@/views/orders/order-details-new')
                }]
            },
            {
                path: 'analytics',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel-plus/funnel/analytics/analytics.v2')
            },
            {
                path: 'analytics-v2',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel-plus/funnel/analytics/analytics.v2')
            },
            {
                path: 'apps',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel-plus/funnel/apps/index'),

                children: [{
                    path: ':appId',
                    meta: { layout: 'steps-children' },
                    component: () =>
                        import ('@/views/funnels/funnel-plus/funnel/apps/app-details')
                }]
            },
            {
                path: 'automations',
                meta: { layout: 'steps-children' },
                component: () =>
                    import ('@/views/funnels/funnel-plus/funnel/automations/index'),
                children: [{
                    path: ':automation',
                    meta: { layout: 'steps-children',isFPlus:true },
                    name: 'nodeData',
                    props: true,
                    component: () =>
                        import ('@/views/funnels/funnel-plus/funnel/automations/automation-v2'),
                    children: [{
                        path: 'v2',
                        meta: { layout: 'steps-children',isFPlus:true },
                        name: 'EmailTemplate',
                        props: true,
                        component: () =>
                            import ('@/views/funnels/funnel-plus/funnel/automations/automation-v2'),
                    }]
                },
                    {
                        path: 'v2/:automation',
                        meta: { layout: 'steps-children',isFPlus:true },
                        name: 'funnelAutomationsV2',
                        props: true,
                        component: () =>
                            import ('@/views/funnels/funnel-plus/funnel/automations/automation-v2'),
                        children: []
                    }]
            },
            {
                path: 'steps/:step',
                name: 'funnel-plus',
                meta: { layout: 'steps-children',isFPlus:true },
                props: true,
                component: () =>
                    import ('@/views/funnels/funnel-plus/steps/index'),

                children: [{
                        path: 'settings',
                        name: 'funnel-plus-step-settings',
                        meta: { layout: 'steps-children' },
                        props: true,
                        component: () =>
                            import ('@/views/funnels/funnel-plus/steps/settings')
                    },
                    {
                        path: '', // Catch all
                        name: 'fplus-step-details',
                        meta: { layout: 'steps-children' },
                        props: true,
                        component: () =>
                            import ('@/views/funnels/funnel-plus/steps/step-details'),
                        children: [
                            {
                                path: 'product/:productId',
                                name: 'fplus-products-side-panel',
                                meta: { layout: 'steps-children',isFPlus:true },
                                props: true,
                                component: () =>
                                    import ('@/views/funnels/funnel-plus/steps/plus-product-side-panel')
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        path: '/email-editor',
        name: 'email-editor',
        meta: { layout: 'email' },
        component: EmailEditor,
        children: [{
                path: '',
                name: 'Home',
                meta: {
                    layout: "email"
                },
                props: true,
                component: () => import ('@/modules/email-editor/src/views/Home.vue'),
                children: [{
                        name: 'Funnel',
                        path: 'funnel/:funnel/:automation/:user/:key',
                        meta: { layout: "email" },
                        props: true,
                    },
                    {
                        name: 'FPlusEmailEditor',
                        path: 'wfunnel/:funnel/:automation/:user/:key',
                        meta: { layout: "email", isPlus:true },
                        props: true,
                    },
                    {
                        name: 'GlobalEmailEditor',
                        path: ':automation/:user/:key',
                        meta: { layout: "email", is_global:true },
                        props: true,
                    },
                ]
            }
        ]
    },
    {
        path: '/page-editor',
        name: 'page-editor',
        meta: { layout: 'page', isFPlus:true },
        props: true,
        component: () =>
            import ('@/modules/page-editor/PageEditor.vue'),
        children: [{
                path: '/',
                name: 'Home',
                meta: {
                    layout: "page",
                    isFPlus:true
                },
                props: true,
                components: {
                    default: () => import ('@/modules/page-editor/src/views/Home.vue'),
                    header: () => import ('@/modules/page-editor/src/components/EditorHeader.vue'),
                },

                children: [{
                        name: 'Funnel',
                        path: 'wfunnel/:funnel/steps/:step/pages/:page/:user',
                        meta: { layout: "page",isFPlus:true },
                        props: true,
                    },
                    {
                        name: 'Layout',
                        path: 'layout/:id',
                        meta: { layout: "page",isFPlus:true },
                        component: () =>
                            import ('@/modules/page-editor/src/components/sidebar/Layout.vue')
                    },
                    {
                        name: 'Settings',
                        path: 'settings',
                        meta: { layout: "page",isFPlus:true },
                        component: () =>
                            import ('@/modules/page-editor/src/components/sidebar/Settings.vue')
                    },
                    {
                        name: 'PageProperties',
                        path: 'properties',
                        meta: { layout: "page",isFPlus:true },
                        component: () =>
                            import ('@/modules/page-editor/src/components/sidebar/PageProperties.vue')
                    },
                    {
                        name: 'Basic',
                        path: ':tab/:id',
                        meta: { layout: "page",isFPlus:true },
                        props: true,
                        component: () =>
                            import ('@/modules/page-editor/src/components/sidebar/PropertiesPanel.vue')
                    },
                ]
            },
            {
                path: 'preview',
                name: 'PreviewEmail',
                meta: {
                    layout: "page"
                },
                component: () =>
                    import ('@/modules/page-editor/src/views/PreviewEmail.vue'),
            }
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    if (['LogIn','SignUp','ShareFunnel','ForgotPassword','PasswordRecovery','NewPassword','SelectAccount'].
        includes(to.name)) {
        next();
    } else {
        if (store.state.token === false) {
            next({name: 'LogIn', query:to.query})
        } else if (!store.state.accountId || store.state.accountId==="0")  {
            next({ name: 'SelectAccount', query:from.query })
        }
        else next();
    }
});

router.afterEach(to => {
    // Vue.nextTick(() => {
    Vue.prototype.$posthog.capture("$pageview", {
            $current_url: to.fullPath
        });
    // });
});

export default router
